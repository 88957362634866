// Fonts
@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Licorice&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Date range picker
@import "~daterangepicker/daterangepicker.css";

//Sweetalert 2
@import '~sweetalert2/dist/sweetalert2.min.css';

//Leaflet
@import '~leaflet/dist/leaflet.css';

body,html{
    height: 100%;
    width: 100%;
}
body{
    background: url('../images/background.jpg') no-repeat top center #d2d2d0;
    background-size: cover;
    font-family: $font-family-sans-serif;

}
.bg-thessaloniki{
    background: url('../images/bg-left.png') no-repeat left top $body-bg;
    background-size: contain;
}
.bg-white,.alert-light{
    background-color: $body-bg !important;
}
input,textarea,select{
    background-color: $body-bg !important;
}
.form-control,.btn-outline-secondary{
    border-color: #a3583a;
}
.btn-success{
    @include button-variant($btnSuccessColor, darken($btnSuccessColor, 7.5%), #fff, lighten($btnSuccessColor,5%), lighten($btnSuccessColor, 10%), darken($btnSuccessColor,30%));
}
.btn-primary{
    @include button-variant($btnPrimaryColor, darken($btnPrimaryColor, 7.5%), #fff, lighten($btnPrimaryColor,5%), lighten($btnPrimaryColor, 10%), darken($btnPrimaryColor,30%));
}
.swal2-title{
    font-size: 1.2rem !important;
}
.swal2-confirm{
    background-color: $btnSuccessColor !important;
    border: 1px solid lighten($btnSuccessColor, 5%) !important;
}
.swal2-confirm:hover, .swal2-confirm:active{
    background-color: lighten($btnSuccessColor, 5%) !important;
    border: 1px solid lighten($btnSuccessColor, 5%) !important;
}
.form-check-input:checked {
    background-color: #884519 !important;
    border-color: #884519;
}
.form-check-label{
    cursor: pointer;
}
h1,h2{
    font-family: 'Licorice', cursive;
    font-size: 5rem;
    color: $btnSuccessColor;
}
h2{
    font-size:2rem;
}
h2.swal2-title{
    font-size: 1.1rem !important;
    font-family: $font-family-sans-serif;
}
h3{
    font-size: 1.1rem;
    color: $btnSuccessColor;
    text-transform: uppercase;
    font-weight: bold;
}
.text-justify{
    text-align: justify;
    text-indent: 2.5rem;
    font-size: 1rem;
}
.text-justify p:last-child{
    margin-bottom: 0;
}
hr{
    height: 3px;
    background: url('../images/bg-line.png') center center no-repeat;
    background-size: cover;
    margin: 0.6rem 0;
}
.text-left{
    text-align: left !important;
}
#color-palette{
    background: url(../images/color-palette.png) right center no-repeat;
    padding-right: 90px !important;
}
.carousel-caption h5{
    background-color: rgb(255 255 255 / 10%) !important;
    border-radius: 5px;
    color: #fff;
}
.nav-tabs .nav-link{
    color: $btnSuccessColor !important;
}
.nav-tabs {
    border-bottom: 1px solid #ebd8cd !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #fbf4f0;
    border-color: #ebd8cd #ebd8cd #fbf4f0 !important;
    color: #495057;
}
#map{
    width: 100%;
    height: 450px;
}
.leaflet-popup-content{
    text-align: center;
}
