// Body
$body-bg: #fbf4f0;

// Typography
$font-family-sans-serif: 'Karla', sans-serif;;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//Buttons
$btnSuccessColor: #884519;
$btnPrimaryColor: #df9152;
